import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';
import { LARGE_PRODUCT_IMAGE_BASE_FRAGMENT } from '@/graphql/_Fragments/LargeProductImage/Base';

export const LARGE_PRODUCT_ENTITY_LIST_SEARCH_FRAGMENT = `
  fragment largeProductEntityListSearchFragment on LargeProduct {
    id
    uid
    schemaCode
    __typename
    name
    images(orderBy: [id_asc]) {
      ...largeProductImageBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    score: _score
  }
  ${LARGE_PRODUCT_IMAGE_BASE_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
`;
